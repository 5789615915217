<template>
	<div class="rc-leaseback">
        <v-container class="rc-padding fluid" style="display: flex; justify-content: center;">
            <v-layout row wrap style="max-width: 1300px;">
                <v-flex class="leaseback__content" xs12 md12 lg7>
                    <h1 class="rc-tituloSection">¿Cómo funciona Leaseback?</h1>
                    <h2 class="content__parrafo">
                        Conoce más sobre los beneficios de invertir en leaseback con <a href="/">RedCapital</a>
                    </h2>
                </v-flex>
                <v-flex xs12 md12 lg5>
                    <Videos urlVideo="/images/videos/Leaseback.mp4" posterVideo="" />
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
    import Videos from "@/components/media/Videos.vue";
	
	@Component({
		components: {
            Videos,
        }
	})
	export default class Leaseback extends Vue {}
</script>

<style lang="scss" scoped>
	.rc-leaseback{
        min-height: 400px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        .leaseback__content{
            text-align: center;
            display: flex;
            flex-flow: column;
            justify-content: center;
            .content__parrafo{
                margin-top: 20px;
                font-weight: normal;
                color: $rc-prim-g1 !important;
                a {
                    color: $rc-prim-n1 !important;
                }
            }
        }
    }
	// Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				.rc-tituloSection{
                    text-align: left;
                    max-width: 630px;
                }
                .content__parrafo{
                    text-align: left;
                    max-width: 490px;
                }
			}@else if $breakpoint == "large"{
				.rc-tituloSection{
                    text-align: left;
                    max-width: 590px;
                }
                .content__parrafo{
                    text-align: left;
                    max-width: 490px;
                }
			}@else if $breakpoint == "medium-to-large"{
				.content__parrafo{
                    margin-bottom: 2rem;
                }
			}@else if $breakpoint == "small-to-medium"{
                .content__parrafo{
                    margin-bottom: 2rem;
                }
			}@else if $breakpoint == "small"{
                .content__parrafo{
                    margin-bottom: 2rem;
                }
            }
		}
	}
</style>